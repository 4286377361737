<template>
    <div v-if="banner" class="global-banner">
        <a :href="banner.LINK" target="_blank">
            <h5>
                {{ banner.DETAIL_TEXT }}
            </h5>
        </a>

    </div>
</template>

<script>

export default {
    name: "global-banner",
    data() {
        return {
        }
    },
    computed: {
        banner() {
            return this.$store.state.common.banner;
        }
    }
}
</script>

<style lang="scss">
.global-banner {
    //position: fixed;
    //top: 0;
    //left: 0;
    position: relative;
    z-index: $z-highest;
    height: $globalBannerHeight;
    width: 100%;
    background-color: $golden-light;
    color: $black;
    text-align: center;
    padding: 8px;
    white-space: nowrap;
    a {
        color: $black !important;
        @include transition();
        &:hover {
            opacity: 0.7;
        }
    }
}
</style>