<template>
	<footer class="the-footer text-center">
		<v-container class="pt-0">
			<divider/>
			<div class="py-8">
				<div class="the-footer__payments">
					<v-img src="@/assets/the-footer/payments-new.png" contain alt="Способы оплаты" />
				</div>

				<v-row class="my-1 justify-center h6 text-center the-footer__phones">
					<v-col cols="12" md="4" lg="3">
						Санкт-Петербург <a href="tel:+79217881818">+7 (921) 788-18-18</a>
					</v-col>
					<v-col cols="12" md="4" lg="3" class="py-0 py-md-3">
						Москва <a href="tel:+79057561113">+7 (905) 756-11-13</a>
					</v-col>
					<v-col cols="12" md="4" lg="3">
						Париж <a href="tel:+33142605875">+33 1 42 60 58 75</a>
					</v-col>
				</v-row>

				<div class="the-footer__socials d-flex justify-center align-center flex-wrap">
					<!--<a href="#" class="mx-2"><img class="d-block" src="@/assets/the-footer/instagram.svg"
												  alt="Instagram"></a>
					<a href="#" class="mx-2"><img class="d-block" src="@/assets/the-footer/facebook.svg" alt="Facebook"></a>
					-->
                    <a href="https://t.me/schastyesweet" target="_blank" class="mx-4 d-flex h6">
                        <img class="d-block mr-2" src="@/assets/the-footer/tg.svg"
                             alt="Telegram">
                        @schastyesweet
                        </a>
                    <a class="mx-4 d-flex h6 d-block" href="mailto:factory@schastye.com">
                        <img class="d-block mr-2" src="@/assets/the-footer/email.svg"
                             alt="Mail">
                        factory@schastye.com</a>
				</div>

				<div class="size2 mt-4">
					© Фабрика "Счастье", {{year}}. <br class="d-sm-none" /> Все права защищены. <br class="d-sm-none" />
          <router-link :to="{name:'terms'}">Условия продажи</router-link>
          и
          <router-link :to="{name:'policy'}">Конфиденциальность</router-link>
				</div>

				<div class="d-flex justify-center mt-8">
					<a href="https://playnext.ru/" target="_blank"><img class="d-block" src="@/assets/the-footer/PlayNext.svg"
																		alt="PlayNext"></a>
				</div>
			</div>
		</v-container>
	</footer>
</template>

<script>
    import Divider from "../components/divider";

    export default {
        name: "the-footer",
        components: {Divider},
		computed: {
            year() {
                return new Date().getFullYear();
			}
		}
    }
</script>

<style lang="scss">
	.the-footer {
		&__payments {
			.v-image {
				margin: 0 auto;
				width: 150px;
				height: 24px;
			}
		}

		&__phones {
			white-space: nowrap;
			margin: 0 auto;
			max-width: 760px;
			@include up($lg) {
				max-width: 1024px;
			}
		}

        &__socials {
            a {
                font-family: $body-font-family;
            }
        }
	}

</style>