<template>
	<header ref="header" class="the-header" v-scroll="handleScroll"
			:class="headerClass"
	>
		<v-container class="py-3 py-md-7 py-lg-5">

			<transition name="burger" appear>
				<burger-menu v-if="withBurgerMenu" class="z-index-2"/>
			</transition>

			<transition name="basket" appear>
				<basket-popup v-if="withBasketPopup" class="z-index-1"/>
			</transition>

			<div class="d-flex d-md-none justify-space-between align-center px-4 z-index-3 the-header__mobile">

				<button @click="onToggleBurgerMenu" class="the-header__hamburger-btn d-flex  flex-column"
						:class="{'the-header__close-btn' : withBurgerMenu}">
					<span></span>
					<span></span>
					<span></span>
				</button>

				<router-link :to="{name: 'home'}">
					<svgicon :name="isLogoWhite?'logo':'logo-dark'" class="the-header__logo "/>
				</router-link>

				<button class="the-header__basket-btn"
						:class="{'with-products':countBasketProducts}"
						@click="onToggleBasket">

					<transition name="fade-quick" mode="out-in">
						<div v-if="withBasketPopup" class="the-header__close-btn">
							<span></span>
							<span></span>
							<span></span>
						</div>
						<div v-else>
							<router-link :to="{name: 'basket'}">
								<svgicon name="shop"/>
							</router-link>
							<div v-if="countBasketProducts" class="the-header__basket-btn-amount">
								{{ countBasketProducts }}
							</div>
						</div>
					</transition>

				</button>
			</div>

			<div class="d-none d-md-flex justify-space-between align-center the-header__desktop">
				<router-link :to="{name: 'home'}">
					<svgicon :name="isLogoWhite?'logo-white':'logo-dark'" class="the-header__logo "/>
				</router-link>

				<div class="d-flex justify-space-between align-center">
					<template v-for="(item, index) of items">
						<!-- TODO: окружить div && dropdowm rename withDropdown -->
						<!--<a v-if="item.scrollTo" href="#" @click.prevent="scrollTo(item)" class="item">{{item.name}}</a>-->
						<div class="the-header__menu-items">
							<div class="the-header__menu-item">
								<router-link :to="item.to"
											 class="item" :class="{selected:item.isSelected}">{{ item.name }}
								</router-link>
								<div v-if="item.dropdown" class="the-header__dropdown">
									<router-link
										v-for="(item, index) of categories" :key="index"
										:to="{name:'category',params:{code:item.CODE}}"
										class="dropdown">{{ item.UF_LIST_NAME || item.NAME }}
									</router-link>
								</div>
							</div>
						</div>
					</template>
				</div>
				<div class="d-flex align-center justify-space-between relative">
					<!--<a href="#" @click.prevent="scrollTo('about')" class="item">EN</a>-->

					<button class="ml-5 ml-lg-7 the-header__basket-btn"
							:class="{'with-products':countBasketProducts}">
						<router-link :to="{name: 'basket'}">
							<svgicon name="shop"/>
						</router-link>
						<div v-if="countBasketProducts" class="the-header__basket-btn-amount">{{ countBasketProducts }}
						</div>
					</button>

					<basket-mini v-if="$route.name !== 'basket' && countBasketProducts"
								 class="the-header__basket-items"/>

				</div>
			</div>

		</v-container>
	</header>
</template>

<script>
import BurgerMenu from './burger-menu.vue';
import BasketPopup from "@/views/Basket/basket-popup";
import {scrollTo} from '@/utils/utils';
import BasketMini from "@/views/Basket/basket-mini";

export default {
	name: "the-header",
	components: {
		BurgerMenu,
		BasketPopup,
		BasketMini,
	},
	data() {
		return {
			isHeaderScrolled: false,
			isHeaderBurgered: false,
		}
	},
	computed: {
		items() {
			return this.$store.state.menuItems.map(el => ({
				...el,
				isSelected: el.id === this.$store.state.selectedMenuItemId
			}));
		},
		categories() {
			return this.$store.state.common.sections;
		},
		headerClass() {
			return {
				'inversed': (
					this.isHeaderInversed
					|| this.isRouteHeaderInversed),
				'scrolled': this.isHeaderScrolled,
				'burgered': this.isHeaderBurgered,
			}
		},
		withBurgerMenu() {
			return this.$store.state.withBurgerMenu;
		},
		isLogoWhite() {
			// стейт может форсировать инверсивный хедер
			return this.$store.state.isHeaderInversed && !this.isHeaderBurgered;
		},
		isHeaderInversed() {
			// стейт может форсировать инверсивный хедер
			return this.$store.state.isHeaderInversed;
		},
		isRouteHeaderInversed() {
			// роут может форсировать инверсивный хедер
			return this.$route.meta.isHeaderInversed;
		},
		headerHeight() {
			return this.$store.state.headerHeight;
		},
		countBasketProducts() {
			return this.$store.state.session?.basket?.length;
		},
		withBasketPopup() {
			return this.$store.state.withBasketPopup;
		},
		isWindowMD() {
			return this.$store.getters.isWindowMD;
		}
	},
	methods: {
		onResize() {
			this.$store.state.headerHeight = this.$refs.header.clientHeight;
		},
		scrollTo(item) {
			// todo set anchor
			//scrollTo(item.scrollTo, -this.$store.state.headerHeight);
			this.$router.push({name: item.name})
		},
		onToggleBasket() {
			if (this.isWindowMD) {
				this.$store.state.withBurgerMenu = false;
				this.$store.state.withBasketPopup = !this.$store.state.withBasketPopup;
				if (this.$store.state.withBasketPopup) setTimeout(() => {
					this.isHeaderBurgered = true;
				}, 100);
				else this.isHeaderBurgered = false;
			}
		},
		onToggleBurgerMenu() {
			this.$store.state.withBasketPopup = false;
			this.$store.state.withBurgerMenu = !this.$store.state.withBurgerMenu;
			if (this.$store.state.withBurgerMenu) setTimeout(() => {
				this.isHeaderBurgered = true;
			}, 100);
			else this.isHeaderBurgered = false;
		},
		handleScroll() {

			this.$store.state.headerHeight = this.$refs.header.clientHeight;
			if (this.withBurgerMenu) return;
			if (this.isHeaderClassWhite) return;

			if (window.scrollY > 100) this.isHeaderScrolled = true;
			else this.isHeaderScrolled = false;
			/*if (window.scrollY > this.headerHeight && window.scrollY < (this.$store.state.introHeight - this.headerHeight)) {
					this.isHeaderScrolled = true;
			} else {
					this.isHeaderScrolled = false;
			}*/

			/*this.isHeaderWhite = false;
			const y = window.scrollY;
			this.$store.state.whiteHeaderSections.forEach(el=>{
					if (y >= el.height - this.headerHeight && y < el.y )) {
							this.isHeaderWhite = true;
					}
})*/

		}
	},
	mounted() {
		window.addEventListener("resize", this.onResize);
		this.onResize();

		//console.log('menuItems: ',this.items );
	},
	destroyed() {
		window.removeEventListener("resize", this.onResize);
	}
}
</script>

<style lang="scss">
.the-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: $z-highest;
	@include transition();
	//background: transparent;
	background: $header-white; //$white;


	/*&.scrolled {
		background: rgba(95, 95, 95, 0.25);//$white;
	}*/

	&.inversed {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));

		&.scrolled {
			background: rgba(95, 95, 95, 0.25);
		}

		&:not(.burgered) {
			.the-header {
				&__logo {
					fill: $white;
				}

				&__basket-btn {
					svg {
						fill: $white;
					}
				}

				&__hamburger-btn {
					span {
						background: $white;
					}
				}

				&__desktop {
					a.item {
						color: $white !important;
						//font-weight: $fw-book;

						&:hover {
							//text-decoration-color: $white;
							border-bottom: 1px solid $white;
						}
					}
				}
			}
		}
	}

	/*button {
		@include transition();

		&:hover {
			opacity: 0.6;
		}
	}*/

	&:not(.inversed) {
		& .the-header__dropdown {
			background: rgba(255, 255, 255, 0.97);
		}
	}

	&__dropdown {

		flex-direction: column;
		position: absolute;
		z-index: 2;
		//background-color: $white;
		opacity: 0.9;
		padding: 10px 20px;
		display: none;
		left: -10px;
		width: 200px;
		text-transform: uppercase;

		& a {
			margin-bottom: 6px;
		}
	}

	&__menu-items {
		position: relative;
	}

	&__logo {
		margin: auto auto;
		display: block;
		width: 52px;
		height: 52px;
		fill: $primary-500;

		@include up($md) {
			width: 57px;
			height: 57px;
		}
		@include up($lg) {
			width: 83px;
			height: 83px;
		}
	}

	.the-header__basket-items {
		position: absolute;
		background: $white;
		width: 500px;
		height: 0;
		overflow: hidden;
		opacity: 0;
		top: -16px;
		right: -16px;
		z-index: $z-highest+1;
		box-shadow: 0 0 12px 1px rgba(34, 60, 80, 0.11);
		transition: opacity .3s;

		&:hover {
			height: unset;
			opacity: 1;
		}
	}

	&__basket-btn {
		width: 24px;
		position: relative;
		top: -1px;
		//z-index: $z-highest+2;

		@include up($md) {
			top: -2px;
			width: 20px;
		}
		@include up($lg) {
			width: 24px;
		}

		svg {
			width: 100%;
			//height: 30px;
		}

		&.with-products {
			svg {
				fill: $black;
			}
		}

		&-amount {
			position: absolute;
			top: -10px;
			right: -11px;
			//z-index: 3;
			//width: 16px;
			padding: 0 5px;
			height: 16px;
			background: $red1;
			border-radius: 8px;
			font-size: 12px;
			line-height: 16px;
			font-weight: $fw-book;
			min-width: 16px;
			color: $white;
		}
	}

	&__basket-btn:hover {
		~ .the-header__basket-items {
			height: unset;
			opacity: 1;
		}
	}

	&__hamburger-btn {
		justify-content: space-between;
		height: 50px;
		padding: 15px 0 15.5px 0;
		border: 0;

		span {
			display: block;
			width: 30px;
			height: 1.5px;
			background: $black;
			@include transition();
		}
	}

	&__mobile {
		position: relative; // важно, чтобы было над бургером
	}

	&__menu-item {
		&:first-child:hover {
			opacity: 1;

			& .the-header__dropdown {
				display: flex;
			}
		}
	}
	a.item {
		display: inline-block;
		margin: 0 6px;
		padding: 0 2px;
		font-family: $header-font-family;
		font-size: $fs-h6;
		letter-spacing: $ls-h6;
		//color: $black !important;
		white-space: nowrap;
		border-bottom: 1px solid transparent;
		position: relative;

		&:not(.router-link-active) {
			color: $black !important;
		}

		&:hover {
			//opacity: 0.6;
			//text-decoration-color: $primary-500;
			border-bottom: 1px solid $black-50;
		}

		&.router-link-active, &.selected {
			border-bottom: 1px solid $golden;
		}



		/*&:first-child {
			//height: 50px;
			//line-height: 46px;
			border: 2px solid $primary-500;
			padding: 4px 16px;
			margin-right: 16px;
			@include transition();

			&:hover, &:active {
				background: $primary-500;
				color: $white !important;
			}
		}*/

		@include up($lg) {
			margin: 0 8px;
			padding: 0 3px;
			font-size: $fs-h5;
			letter-spacing: $ls-h5;
		}
	}

	// for both burger btn and basket btn
	&__close-btn {
		position: relative;
		padding: 0;
		height: 50px;
		width: 30px;

		.the-header__basket-btn & {
			left: -3px;
		}


		span {
			display: block;
			width: 30px;
			height: 1.5px;
			background: $black;
			@include transition();

			&:nth-child(1) {
				position: absolute;
				top: 50%;
				transform: rotate(45deg) translateY(-50%);
			}

			&:nth-child(3) {
				display: none;
			}

			&:nth-child(2) {
				position: absolute;
				top: 50%;
				transform: rotate(-45deg) translateY(-50%);
			}
		}
	}
}

.burger-enter-active, .burger-leave-active {
	transition: transform .2s
}

.burger-enter, .burger-leave-to {
	transform: translateX(-100%)
}

.basket-enter-active, .basket-leave-active {
	transition: transform .2s
}

.basket-enter, .basket-leave-to {
	transform: translateX(100%)
}

</style>