<template>
	<div v-if="withBasketPopup" class="page--padding basket-popup">
		<section class="section--padding text-center">
			<v-container>
				<h3 v-if="isEmpty">Your cart is empty</h3>
				<template v-else>
					<h3 class="mb-10 mb-md-12">Your cart</h3>
					<basket-item v-for="(item,index) of items" :key="'basket'+index" :item="item"/>
					<router-link :to="{name:'basket'}">
						<btn-primary large class="large mx-auto" label="Checkout"/>
					</router-link>
				</template>
			</v-container>
			<!--<button @click="popupClose" class="pop-up-basket__close"></button>-->
		</section>
	</div>
</template>

<script>
    import BasketItem from "./basket-item";
    import BtnPrimary from '../../components/btn-primary';

    export default {
        name: "basket-popup",
        components: {
            BasketItem,
            BtnPrimary
        },
        computed: {
            isEmpty() {
                return !this.$store.state.session?.basket?.length;
			},
            withBasketPopup() {
                return this.$store.state.withBasketPopup;
            },
			items() {
                return this.$store.state.session?.basket;
			}
        },
        methods: {
            popupClose() {
                this.$store.state.withBasketPopup = false;
            }
        }
    }

</script>

<style lang="scss">
	.basket-popup {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background: $white; //$shadow;
		overflow: hidden;
		//overflow: auto;

		section {
			overflow-y: scroll;
			height: 100vh;
		}

		/*&__close {
			position: absolute;
			background: url("../../assets/close.svg") no-repeat center;
			background-size: cover;
			top: 55px;
			right: 55px;
			width: 18px;
			height: 18px;

			&:hover {
				opacity: 0.7;
			}
		}*/
	}
</style>