<template>
	<basket-items :items="items" mini/>
</template>

<script>
import BasketItems from "@/views/Basket/basket-items";

export default {
	name: "basket-mini",
	components: {BasketItems},
	computed: {
		items() {
			return this.$store.state.session?.basket;
		},
	},
}
</script>

<style lang="scss">

</style>